import styled from "styled-components";
import vars from "../../styles/vars";

export const LoaderWrapper = styled.div`
  height: 100%;
  background-color: ${vars.colors.black};
  position: relative;
  z-index: 220000;
`;

export const Spinner = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 100px;
    max-width: 10vw;
    max-height: 10vh;
    animation: animateLoading 1.5s infinite linear;
  }

  @keyframes animateLoading {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Text = styled.h4`
  font-size: 1.2rem;
  position: absolute;
  bottom: 5vh;
  left: 20px;
  right: 20px;
  text-align: center;
  color: ${vars.colors.white};
  animation: animateText 2s infinite linear;

  @keyframes animateText {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
`;
