import { createGlobalStyle, css } from "styled-components";
import vars from "../../styles/vars";

type Props = {
  primaryColor: string;
  secondaryColor: string;
  fontPrimary?: string;
  fontSecondary?: string;
  fontTertiary?: string;
};

export default createGlobalStyle<Props>`
  ${({ fontPrimary }) => {
    if (fontPrimary === undefined) return;
    return css`
      @font-face {
        font-family: "Primary";
        src: url(${fontPrimary}) format("truetype");
        font-style: normal;
        font-display: swap;
      }
    `;
  }};

  ${({ fontSecondary }) => {
    if (fontSecondary === undefined) return;
    return css`
      @font-face {
        font-family: "Secondary";
        src: url(${fontSecondary}) format("truetype");
        font-style: normal;
        font-display: swap;
      }
    `;
  }};

  ${({ fontTertiary }) => {
    if (fontTertiary === undefined) return;
    return css`
      @font-face {
        font-family: "Tertiary";
        src: url(${fontTertiary}) format("truetype");
        font-style: normal;
        font-display: swap;
      }
    `;
  }};

  :root {
    --color-primary: ${({ primaryColor }) => primaryColor};
    --color-secondary: ${({ secondaryColor }) => secondaryColor};
    --font-size: 1.25
  }

  @media (max-width: 850px) {
    --font-size: 1.125
  }

  *, *:before, *:after {
    box-sizing: border-box;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  html {
    font-size: 1.25em;
    font-family: ${vars.fontPrimary};
    font-weight: normal;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
  }

  h1,
  h3,
  p {
    line-height: 1em;
    margin: 0;
  }

  h1 {
    font-size: ${55 / vars.rootSize}em;
    font-weight: 800;
  }

  h3 {
    font-size: ${26 / vars.rootSize}em;
    font-weight: normal;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${vars.fontTertiary};
  }

  a {
    text-decoration: none;
  }

  img, svg, iframe, video {
    align-self: center;
    flex-shrink: 0;
  }

  img {
    max-width: 100%;
    object-fit: cover;
  }

  button {
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  [data-reach-tooltip] {
    z-index: 10000;
  }
`;
