import React, { useCallback, useRef, useState } from "react";
import { ButtonContainer, ButtonDivider, Container, Wrapper } from "./styles";
import {
  AtRiskComponent,
  BannerComponent,
  IntroComponent,
  Quote,
  Replay,
  Title,
  UserAnswer,
} from "./components";
import Button from "../button/button";
import Summary from "./summary/summary";
import { Answer, DebriefComponent, SceneDataProps } from "../../types";
import { useApp } from "../../state/app";
import { MODE } from "../../config/consts";
import { useHistory } from "react-router-dom";
import scorm from "../../services/scorm";

const MAP = {
  richText: IntroComponent,
  titledImage: BannerComponent,
  quote: AtRiskComponent,
  quoteWithString: Quote,
  title: Title,
  userAnswer: UserAnswer,
  replay: Replay,
  summary: Summary,
};

type DebriefProps = {
  sections: { components: DebriefComponent[] }[];
  sceneId: string;
  answers: Answer[];
  nextButtonBackground?: string;
  nextButtonColor?: string;
  backgroundColor?: string;
  buttonPaddingTop?: string;
  buttonPaddingBottom?: string;
  lastScene?: boolean;
};

export default function Debrief({
  sections,
  sceneId,
  answers,
  nextButtonBackground,
  nextButtonColor,
  backgroundColor,
  buttonPaddingTop,
  buttonPaddingBottom,
  lastScene = false,
}: DebriefProps) {
  const {
    debriefIndex,
    client,
    setUserAnswer,
    projectID,
    debriefId,
    debriefMode,
    language,
  } = useApp();
  const [currentSectionIndex, updateCurrentSectionId] = useState(0);
  const wrapper = useRef<any>(null);
  const history = useHistory();

  React.useEffect(() => {
    console.log({ lastScene });
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  React.useMemo(() => {
    document.title = "Debrief";
  }, []);

  const handleNextButton = useCallback(() => {
    updateCurrentSectionId((index) => {
      return index >= sections?.length - 1 ? index : index + 1;
    });
    wrapper?.current?.scrollTo(0, 0);
  }, [updateCurrentSectionId, wrapper, sections?.length]);

  const handleBackButton = useCallback(() => {
    updateCurrentSectionId((index) => (index <= 0 ? index : index - 1));
    wrapper?.current?.scrollTo(0, 0);
  }, [updateCurrentSectionId, wrapper]);

  const handleDecisionSelected = React.useCallback(
    (sceneData: SceneDataProps) => {
      const { userAnswer } = useApp.getState();
      if (debriefMode && debriefId) {
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${debriefId}`
            : `/${projectID}/scene/${debriefId}`,
        );
      } else {
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${sceneData.answerSceneId}`
            : `/${projectID}/scene/${sceneData.answerSceneId}`,
        );
      }
      userAnswer[sceneData.sceneId] = sceneData.text;
      setUserAnswer(userAnswer);
    },
    [history, setUserAnswer, debriefId, debriefMode, projectID],
  );

  React.useEffect(() => {
    updateCurrentSectionId(debriefIndex);
  }, [debriefIndex]);

  React.useEffect(() => {
    useApp.getState().setDebriefMode(false);
  }, []);

  React.useEffect(() => {
    wrapper.current?.focus();
  }, []);

  return (
    <Wrapper ref={wrapper} style={{ backgroundColor }}>
      {sections?.map((items, pageIndex) => {
        return (
          <div
            key={`parent_${pageIndex}`}
            className={`section section--${
              pageIndex === currentSectionIndex ? "visible" : "hidden"
            }`}
          >
            {items.components.map(({ id, ...props }, index) => {
              if (!id) return <React.Fragment key={index}></React.Fragment>;
              // @ts-ignore
              const Component = MAP[id];
              return (
                <Component
                  key={`${id}${id}-${index}`}
                  // @ts-ignore
                  {...props[id]}
                  debriefSceneId={sceneId}
                  currentSectionIndex={currentSectionIndex}
                />
              );
            })}
            <Container variant="center">
              <ButtonContainer
                style={{
                  paddingTop: `${buttonPaddingTop}rem`,
                  paddingBottom: `${buttonPaddingBottom}rem`,
                }}
              >
                {!(currentSectionIndex === 0) && (
                  <>
                    <Button
                      variant="debrief"
                      onClick={handleBackButton}
                      backgroundColor={client.primaryColor}
                      color={"#FFFFFF"}
                    >
                      Back
                    </Button>
                    <ButtonDivider />
                  </>
                )}
                {!(currentSectionIndex >= sections.length - 1) ? (
                  <Button
                    variant="debrief"
                    onClick={handleNextButton}
                    backgroundColor={client.primaryColor}
                    color={"#FFFFFF"}
                  >
                    Next
                  </Button>
                ) : (
                  <>
                    {answers.length > 0 &&
                      answers.map((answer, index) => {
                        const text = answer?.text?.[language] ?? "";
                        return (
                          <Button
                            backgroundColor={nextButtonBackground}
                            color={nextButtonColor}
                            key={`${text}-${index}${index}`}
                            onClick={() => {
                              handleDecisionSelected({
                                sceneId,
                                answerSceneId: answer.sceneId,
                                text,
                              });
                            }}
                          >
                            {text}
                          </Button>
                        );
                      })}
                  </>
                )}
              </ButtonContainer>
            </Container>
          </div>
        );
      })}
    </Wrapper>
  );
}
