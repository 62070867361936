import React from "react";
import { SummaryWrapper, Text } from "./styles";
import Video from "./video";
import { Container } from "../styles";

type Props = {
  text: string;
  video: string;
  subtitle?: string;
};

export default function Summary({ text, video, subtitle = "" }: Props) {
  return (
    <SummaryWrapper>
      <Container>
        <Text dangerouslySetInnerHTML={{ __html: text }} />
        <Video videoSrc={video} subtitle={subtitle} />
      </Container>
    </SummaryWrapper>
  );
}
