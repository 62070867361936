import React from "react";
import env from "../env";
import langData from "../lang-data";
import { useHistory, useParams } from "react-router-dom";
import { createNodeFromScenes, getOrderedScenes } from "../utils";
import { useApp } from "../state/app";
import { MODE } from "../config/consts";
import scorm from "../services/scorm";

console.log({ MODE });

let lastFetchedProjectID: string = "";

export default function useAppLogic() {
  const languageSelected = useApp((state) => state.languageSelected);
  const language = useApp((state) => state.language);
  const scenes = useApp((state) => state.scenes);
  const setScenes = useApp((state) => state.setScenes);
  const appText = useApp((state) => state.appText);
  const setAppText = useApp((state) => state.setAppText);
  const [langOptions, setLangOptions] = React.useState(langData);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { projectID } = useParams<{ projectID: string }>();

  React.useEffect(() => {
    useApp.getState().setprojectID(projectID);
  }, [projectID]);

  React.useEffect(() => {
    if (MODE.startsWith("scorm")) return;
    const { setUserAnswer, setLanguageSelected, setLanguage } =
      useApp.getState();
    if (localStorage.getItem(env.localStorage.language)) {
      setLanguage(JSON.parse(localStorage.getItem(env.localStorage.language)!));
    }
    if (localStorage.getItem(env.localStorage.userAnswer)) {
      setUserAnswer(
        JSON.parse(localStorage.getItem(env.localStorage.userAnswer)!),
      );
    }
    if (localStorage.getItem(env.localStorage.languageSelected)) {
      setLanguageSelected(
        JSON.parse(localStorage.getItem(env.localStorage.languageSelected)!),
      );
    }
    if (MODE.startsWith("web")) return;
    if (projectID !== lastFetchedProjectID) {
      lastFetchedProjectID = projectID;
      setLoading(true);
      fetch(`${env.apiEndpoint}/api/project-data/${projectID}`)
        .then((res) => res.json())
        .then((res) => {
          setLangOptions(res.data.locales);
          useApp.getState().setClient(res.data.client);
          useApp.getState().setVideoTranscript(res.data.videoTranscript);
          setAppText(res.data.appText);
          setScenes(res.data.scenes);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [history, projectID]);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;

    if (!scorm.isConnected()) {
      // @todo: show some UI state to let the user know they're not connected to SCORM
      console.log("Not connected to SCORM");
      return;
    }

    const data = scorm.getData();
    const progress = scorm.getProgress();
    const status = scorm.getStatus();
    console.log("data", data);
    console.log("progress", progress);
    console.log("status", status);
    useApp.getState().setUserAnswer(data?.userAnswer ?? {});
    useApp.getState().setLanguageSelected(data?.languageSelected ?? false);

    if (
      data?.sceneId &&
      history.location.pathname !== `/scene/${data?.sceneId}`
    ) {
      history.push(`/scene/${data?.sceneId}`);
    }

    function onHistoryChange(location: any) {
      if (!location.pathname.includes("scene")) return;
      const pathnameParts = location.pathname.split("/");
      const sceneId = pathnameParts[pathnameParts.length - 1];

      scorm.setData({ sceneId });

      const node = createNodeFromScenes(scenes, scenes[0]);
      if (!node) return;
      const orderedScenes = getOrderedScenes(node);
      // const sceneIndex = orderedScenes.scenes[sceneId] ?? 0;
      const isLastScene = orderedScenes.lastSceneIds.includes(sceneId);

      if (isLastScene) {
        // scorm.complete();
      } else {
        // scorm.setProgress(
        //   useApp.getState().debriefMode
        //     ? 100
        //     : (100 / orderedScenes.maxIndex) * sceneIndex,
        // );
      }
    }

    onHistoryChange(history.location);
    return history.listen(onHistoryChange);
  }, [projectID, history, scenes]);

  return {
    loading,
    scenes,
    appText,
    langOptions,
    languageSelected,
    language,
  };
}
