import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import { AnimateWrapper } from "./styles";

type Props = {
  children?: any;
  isVisible?: boolean;
};

export default function Animate({ children, isVisible = true }: Props) {
  const [visible, setVisible] = useState(isVisible);

  const toggleVisible = React.useCallback(() => {
    setVisible((prevState) => !prevState);
  }, []);

  return (
    <Waypoint
      onEnter={toggleVisible}
      onLeave={toggleVisible}
      topOffset={"50%"}
      fireOnRapidScroll={true}
    >
      <AnimateWrapper visible={visible}>{children}</AnimateWrapper>
    </Waypoint>
  );
}
