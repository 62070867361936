import styled from "styled-components";

type Props = {
  visible: boolean;
};

export const AnimateWrapper = styled.div<Props>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  height: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  background-size: cover;
  background-position: center;
  transition: ${({ visible }) =>
    visible ? "opacity 2s, visibility 0s 0s" : "opacity 2s, visibility 0s 1s"};
  z-index: 1000000;
`;
