import styled from "styled-components";

import { ButtonWrapper } from "../button/styles";
import { HtmlWrapper } from "../html/styles";
import { Container } from "../../styles/elements";
import vars from "../../styles/vars";

export const TextLinkWrapper = styled.div`
  height: 100%;
  background-color: black;

  & video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: auto;
    z-index: 2;

    @media (max-aspect-ratio: 16 / 9) {
      height: 100vh;
      width: auto;
    }
  }

  ${Container} {
    height: 100%;
  }
`;

export const Line = styled.div`
  position: relative;
  width: 40vw;
  max-width: 280px;
  height: 2px;
  background-color: #fff;
  margin: 6vh auto 7vh auto;
`;

export const TextLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ${HtmlWrapper} {
    text-align: center;

    & h3 {
      font-size: 1.6em;
      font-weight: 600;
    }

    & p {
      margin-bottom: 1em;
      line-height: 1.3;
    }

    @media (max-width: 600px) {
      & h3 {
        font-size: 1.3em;
      }

      & p {
        font-size: 0.8em;
      }
    }
  }
`;

export const TextWrapper = styled.div`
  max-width: 920px;
  margin: auto;
  padding-top: 22vh;
  padding-left: 1em;
  padding-right: 1em;

  @media (max-width: 500px) {
    padding-top: 22vw;
  }
`;

export const TitleWrapper = styled.div`
  ${HtmlWrapper}:first-child {
    font-family: ${vars.fontPrimary};
  }
`;

export const ButtonContainer = styled.div`
  height: 20vh;
  margin-bottom: 40px;

  & ${ButtonWrapper} {
    border: none;
    margin-top: 10vh;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }
`;
