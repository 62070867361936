import React from "react";
import { VideoWrapper } from "./styles";
import { Answer, Text } from "../../../types";
import { Waypoint } from "react-waypoint";
import AnimatePlay from "../../video/animated-play";
import VideoControls from "../../video/video-controls";
import useVideoControls from "../../../hooks/use-video-controls";

type Props = {
  sceneId?: string;
  videoSrc: string;
  subtitle?: string;
  answers?: Answer[];
  answersTime?: number;
  answersShow?: Text;
  countDownColor?: string;
};

export default function Video({ videoSrc, subtitle }: Props) {
  const {
    videoRef,
    muted,
    togglePlay,
    language,
    playing,
    playAnimation,
    fastForward,
    toggleCaption,
    caption,
    toggleMute,
    rewind,
    setPlaying,
  } = useVideoControls();

  const componentUnMount = React.useCallback(() => {
    if (videoRef.current) {
      videoRef.current?.pause();
      setPlaying(false);
    }
  }, [setPlaying, videoRef]);

  return (
    <Waypoint
      onLeave={componentUnMount}
      topOffset={"50%"}
      fireOnRapidScroll={true}
    >
      <VideoWrapper>
        <video
          playsInline
          ref={videoRef}
          controls={false}
          muted={muted}
          onClick={togglePlay}
          crossOrigin={"anonymous"}
        >
          <source src={videoSrc} type="video/mp4" />
          <track
            label={language}
            kind="captions"
            srcLang={language}
            src={subtitle}
            default
          />
        </video>
        {playAnimation && playing && <AnimatePlay play={playing} />}
        {playAnimation && !playing && <AnimatePlay play={playing} />}
        <VideoControls
          show={!playing}
          togglePlay={togglePlay}
          toggleMute={toggleMute}
          muted={muted}
          rewind={rewind}
          fastForward={fastForward}
          caption={caption}
          toggleCaption={toggleCaption}
          playing={playing}
        />
      </VideoWrapper>
    </Waypoint>
  );
}
