import React from "react";
import { Answer, LocaleValue } from "../../types";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import {
  AnswerWrapper,
  CountDown,
  Decision,
  Decisions,
  FieldSet,
  ImageWrapper,
  Question,
} from "./styles";
import { useApp } from "../../state/app";
import { useHistory } from "react-router-dom";

type Props = {
  sceneId: string;
  imageSrc: string;
  answers?: Answer[];
  answersTime?: number;
  answersShow?: string;
  countDownColor?: string;
  question?: LocaleValue;
  lastScene?: boolean;
};

export default function Image({
  sceneId,
  imageSrc,
  answers,
  answersTime,
  answersShow,
  question,
  lastScene = false,
}: Props) {
  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  const showSetting = useApp((state) => state.showSetting);
  const language = useApp((state) => state.language);
  const projectID = useApp((state) => state.projectID);
  const setUserAnswer = useApp((state) => state.setUserAnswer);
  const debriefMode = useApp((state) => state.debriefMode);
  const debriefId = useApp((state) => state.debriefId);

  const questionRef = React.useRef<HTMLFieldSetElement>(null);

  const history = useHistory();

  return (
    <ImageWrapper>
      <img src={imageSrc} alt="" />

      <FieldSet
        showDecisions={true}
        aria-hidden={showSetting}
        tabIndex={!showSetting ? 0 : -1}
        ref={questionRef}
      >
        {question && (
          <legend>
            <Question aria-hidden={showSetting} showQuestion={!!question}>
              <h1 tabIndex={-1}>{question}</h1>
            </Question>
          </legend>
        )}
        <Decisions showDecisions={true}>
          <CountDown show={true} countTimeTime={answersTime} />
          {answers &&
            answers.length > 0 &&
            answers.map((answer, index) => {
              const text = answer?.text?.[language] ?? "";
              return (
                <Decision
                  tabIndex={!showSetting ? 0 : -1}
                  aria-hidden={showSetting}
                  onClick={() => {
                    const sceneData = {
                      sceneId,
                      answerSceneId: answer.sceneId,
                      text,
                    };
                    if (debriefMode && debriefId) {
                      history.push(
                        MODE.startsWith("scorm") || MODE.startsWith("web")
                          ? `/scene/${debriefId}`
                          : `/${projectID}/scene/${debriefId}`,
                      );
                      const { userAnswer } = useApp.getState();
                      userAnswer[sceneData.sceneId] = sceneData.text;
                      setUserAnswer(userAnswer);
                    } else {
                      history.push(
                        MODE.startsWith("scorm") || MODE.startsWith("web")
                          ? `/scene/${sceneData.answerSceneId}`
                          : `/${projectID}/scene/${sceneData.answerSceneId}`,
                      );
                      const { userAnswer } = useApp.getState();
                      userAnswer[sceneData.sceneId] = sceneData.text;
                      setUserAnswer(userAnswer);
                    }
                  }}
                  key={index}
                >
                  <AnswerWrapper>{text}</AnswerWrapper>
                </Decision>
              );
            })}
        </Decisions>
      </FieldSet>
    </ImageWrapper>
  );
}
