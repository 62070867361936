export default {
  rootSize: 20,
  fontPrimary: `"Primary", Arial, sans-serif`,
  fontSecondary: `"Secondary", "Times New Roman",Times,serif`,
  fontTertiary: `"Tertiary", "Times New Roman",Times,serif`,
  responsiveMin: 850,
  responsiveMax: 1388,
  colors: {
    primary: "#ffb600",
    gray300: "#C4C4C4",
    secondary: "#e0301e",
    white: "#fff",
    white950: "#dedede",
    black: "#000",
    gray100: "#F8F8F8",
    black950: "#2d2d2d",
    black900: "#464646",
    black850: "#7d7d7d",
    black800: "#1A1C2E",
    black750: "#1D1D1D",
    green: "#05D6AD",
    dfsPrimary: "#E21B84",
  },
};
