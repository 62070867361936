import React, { ForwardedRef } from "react";
import { ControlsButton, VideoControlsWrapper } from "./styles";
import { useApp } from "../../state/app";

type VideoControlsProps = {
  show: boolean;
  togglePlay: (e: React.SyntheticEvent) => void;
  muted: boolean;
  toggleMute: () => void;
  rewind: () => void;
  fastForward: () => void;
  caption: boolean;
  toggleCaption: () => void;
  playing: boolean;
};

export default React.forwardRef(function VideoControls(
  {
    show,
    togglePlay,
    toggleMute,
    muted,
    rewind,
    fastForward,
    caption,
    toggleCaption,
    playing,
  }: VideoControlsProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { showSetting } = useApp();
  const [active, setActive] = React.useState(-1);

  if (!show) return <></>;

  return (
    <VideoControlsWrapper ref={ref} show={show}>
      <ControlsButton
        active={muted}
        backgroundImage={"assets/mute.svg"}
        focus={active === 0}
        onClick={toggleMute}
        aria-labelledby="mute"
        onFocus={() => setActive(0)}
        onMouseOver={() => setActive(0)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
        tabIndex={showSetting ? -1 : 0}
      >
        Mute On/Off
        <span id="mute">{!muted ? "Mute" : "Unmute"}</span>
      </ControlsButton>
      <ControlsButton
        backgroundImage={"assets/back-10.svg"}
        focus={active === 1}
        onClick={rewind}
        aria-labelledby="back"
        onFocus={() => setActive(1)}
        onMouseOver={() => setActive(1)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        Back 10 Seconds
        <span id="back">Back 10 Seconds</span>
      </ControlsButton>
      <ControlsButton
        active={!playing}
        backgroundImage={"assets/pause-play.svg"}
        focus={active === 2}
        onClick={togglePlay}
        title="play"
        aria-labelledby="play"
        onFocus={() => setActive(2)}
        onMouseOver={() => setActive(2)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        {show && <span id="play">{!playing ? "Play" : "Pause"}</span>}
      </ControlsButton>
      <ControlsButton
        backgroundImage={"assets/forward-10.svg"}
        focus={active === 3}
        onClick={fastForward}
        aria-labelledby="forward"
        onFocus={() => setActive(3)}
        onMouseOver={() => setActive(3)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        Forward 10 Seconds
        <span id="forward">Forward 10 Seconds</span>
      </ControlsButton>
      <ControlsButton
        specialActive={caption}
        backgroundImage={"assets/captions-off.svg"}
        focus={active === 4}
        onClick={toggleCaption}
        aria-labelledby="caption"
        onFocus={() => setActive(4)}
        onMouseOver={() => setActive(4)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        Caption On/Off
        <span id="caption">{!caption ? "Caption On" : "Caption Off"}</span>
      </ControlsButton>
    </VideoControlsWrapper>
  );
});
