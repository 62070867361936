import { Scene, Text, TreeNode } from "./types";
import { fluidRange } from "polished";
import vars from "./styles/vars";

export function formatText(text: Text | undefined, language: string) {
  if (text) {
    return text[language];
  }
}

interface ResponsiveUnitProps {
  size: number;
  prop: string;
  ratio?: number;
}

export function responsiveUnit({ size, prop, ratio = 1 }: ResponsiveUnitProps) {
  return fluidRange(
    { toSize: `${size}px`, fromSize: `${size * ratio}px`, prop },
    `${vars.responsiveMin}px`,
    `${vars.responsiveMax}px`,
  );
}

export function createNodeFromScenes(
  scenes: Scene[],
  scene?: Scene,
  parentScene?: Scene,
  node?: TreeNode,
  index = 0,
): undefined | TreeNode {
  if (!scene || !scene.props) {
    return node;
  }
  const children: TreeNode[] = [];
  scene.props.answers.forEach((answer) => {
    const child = createNodeFromScenes(
      scenes,
      scenes.find((scene) => scene && scene.sceneId === answer.sceneId),
      scene,
      node,
      index + 1,
    );
    if (child) {
      children.push(child);
    }
  });
  return {
    index,
    type: scene.type,
    sceneId: scene.sceneId,
    parentId: parentScene?.sceneId ?? null,
    componentId: scene.componentId,
    children,
  };
}

type OrderedScenes = {
  scenes: { [sceneId: string]: number };
  maxIndex: number;
  lastSceneIds: string[];
};
const scenes: { [sceneId: string]: number } = {};
let maxIndex = 0;
const lastSceneIds: string[] = [];

export function getOrderedScenes(node: TreeNode): OrderedScenes {
  scenes[node.sceneId] = node.index;
  maxIndex = Math.max(maxIndex, node.index);

  if (node.componentId === "debrief" && !lastSceneIds.includes(node.sceneId)) {
    lastSceneIds.push(node.sceneId);
  }

  if (node.type !== "reference") {
    node.children.forEach((child) => {
      maxIndex = Math.max(maxIndex, child.index);
      getOrderedScenes(child);
    });
  }

  return { scenes, maxIndex, lastSceneIds };
}
