import React from "react";
import { MODE } from "../../config/consts";
import { HelmetProvider } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import IntroLayout from "../intro-layout/intro-layout";
import Scene from "../scene/scene";
import TextLink from "../text-link/text-link";
import Video from "../video/video";
import Image from "../image/image";
import Loader from "../loader/loader";
import Debrief from "../debrief/debrief";
import Head from "../head/head";
import useAppLogic from "../../hooks/use-app-logic";
import Locale from "../locale/locale";
import LineOfBusiness from "../line-of-business/line-of-business";
import scorm from "../../services/scorm";
import { useApp } from "../../state/app";

type ComponentMap = {
  [key: string]: React.FC<any>;
};

const componentMap: ComponentMap = {
  dropdown: LineOfBusiness,
  introLayout: IntroLayout,
  textLink: TextLink,
  video: Video,
  image: Image,
  loader: Loader,
  debrief: Debrief,
};

export default function App() {
  const { loading, scenes, langOptions, languageSelected, language } =
    useAppLogic();

  React.useEffect(() => {
    if (!scorm.isConnected()) return;
    const data = scorm.getData();
    console.log("DATA", data);
    useApp.getState().setLanguage(data?.language ?? "en");
  }, []);

  if (loading) return <Loader />;

  if (scenes.length === 0) return null;

  return (
    <HelmetProvider>
      <Head />
      {languageSelected || langOptions.length <= 1 ? (
        <Switch>
          {scenes.map((scene: any, index: number) => {
            if (!componentMap.hasOwnProperty(scene.componentId)) return null;
            const Component = componentMap[scene.componentId];
            let path =
              index === 0
                ? "/:projectID"
                : `/:projectID/scene/${scene.sceneId}`;
            if (MODE.startsWith("scorm") || MODE.startsWith("web")) {
              path = index === 0 ? "/" : `/scene/${scene.sceneId}`;
            }

            return (
              <Route
                exact
                path={path}
                key={scene.sceneId}
                render={() => {
                  return (
                    <Scene
                      showSettings={!scene.props?.skippableVideo?.[language]}
                    >
                      <Component
                        sceneId={scene.sceneId}
                        {...scene.props}
                        answers={scene.props.answers}
                      />
                    </Scene>
                  );
                }}
              />
            );
          })}
        </Switch>
      ) : (
        <Locale locales={langOptions} />
      )}
    </HelmetProvider>
  );
}
