import styled from "styled-components";
import vars from "../../styles/vars";
import { StylesConfig } from "react-select";

export const colorStyles: StylesConfig = {
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "",
      color:
        isSelected || isFocused
          ? "rgba(255,255,255, 1)"
          : "rgba(255,255,255, 0.7)",
      border:
        isSelected || isFocused ? "1px solid rgba(255,255,255, 1)" : "none",
      cursor: "pointer",
      fontSize: "1.1em",
      fontWeight: "bold",
      fontFamily: "Primary, Times New Roman, Times, serif",
      ":hover": {
        ...styles[":hover"],
      },
      ":active": {
        ...styles[":hover"],
        color: "rgba(255,255,255, 1)",
        border: "1px solid rgba(255,255,255, 1)",
      },
    };
  },
  control: (styles, { menuIsOpen, isFocused, hasValue }) => ({
    ...styles,
    backgroundColor: "transparent",
    border:
      isFocused && !menuIsOpen && !hasValue
        ? "2px solid rgb(255, 255, 255)"
        : "none",
    borderBottom: "2px solid rgb(255, 255, 255)",
    boxShadow: "none",
    borderRadius: "0",
    ":active": {
      ...styles[":active"],
      border: "none",
    },
    ":hover": {
      ...styles[":hover"],
      border: "unset",
      borderBottom: "2px solid rgb(255, 255, 255)",
    },
    svg: {
      width: "auto",
      height: "2em",
      transition: "transform 0.6s ease",
      color: "rgb(255,255,255)",
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0)",
      ":hover": {
        ...styles[":hover"],
        color: "rgb(255,255,255)",
      },
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "0",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    paddingRight: "0",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingRight: "0",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  input: (styles) => ({
    ...styles,
    marginLeft: "0",
    cursor: "text",
    fontSize: "1.6em",
    fontFamily: "Tertiary, Times New Roman, Times, serif",
    color: "rgb(255,255,255)",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "1.6em",
    fontFamily: "Tertiary, Times New Roman, Times, serif",
    color: "rgb(255,255,255)",
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    fontSize: "1.6em",
    fontFamily: "Tertiary, Times New Roman, Times, serif",
    color: "rgb(255,255,255)",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "rgba(255,255,255,0.1)",
    margin: "1em 0",
  }),
  menuList: (styles) => ({
    ...styles,
    backgroundColor: "rgba(255,255,255,0.1)",
    padding: "0.5em ",
    maxHeight: "320px",
  }),
  container: (styles) => ({
    ...styles,
    marginBottom: "4em",
  }),
};

export const LineOfBusinessWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: url("assets/locale-background.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  background-color: rgba(000, 000, 000);
  overflow-y: auto;
`;

export const ContentWrapper = styled.section`
  padding: 15vh 1em 1em 1em;
  max-width: 1000px;
  width: 100%;
  color: ${vars.colors.white};

  button {
    color: black;
  }

  label {
    display: inline-block;
    font-size: 1.6em;
  }

  h1 {
    font-size: 1.2em !important;
    font-family: Primary, Arial, sans-serif;
    margin-bottom: 1em;
  }

  h2,
  h3 {
    font-size: 1.6em;
  }

  @media (max-width: 700px) {
    h1,
    h2 {
      font-size: 1.2em;
    }
  }
`;

export const Description = styled.div`
  padding-bottom: 1rem;

  h2,
  h3 {
    font-size: 1.3em;
  }

  p {
    font-size: 1rem;
    margin: 1rem 0;
    font-family: ${vars.fontPrimary};
  }
`;
