import styled from "styled-components";
import { lighten } from "polished";
import vars from "../../styles/vars";
import { ButtonWrapper } from "../button/styles";

export const IntroLayoutWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
type Props = {
  browser: string;
};

export const IntroContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  width: 70vw;
  margin: ${({ browser }) => (browser === "ie" ? 0 : "auto")};

  @media (max-width: 1100px) {
    width: calc(100vw - 100px);
  }

  @media (max-width: 700px) {
    width: calc(100vw - 50px);
  }

  @media (max-width: 500px) {
    width: calc(100vw - 30px);
  }
`;

type IntroBannerProps = {
  backgroundImage: string;
};

export const IntroBanner = styled.div<IntroBannerProps>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  height: 15vw;
  width: 100%;
  min-height: 200px;

  @media (max-width: 600px) {
    min-height: 150px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;

  @media (max-width: ${vars.responsiveMin}px) {
    flex-direction: column;
  }
`;

export const ItemContainer = styled.div`
  flex: 80%;
  width: 80%;

  @media (max-width: 850px) {
    flex: 100%;
    width: 100%;
  }
`;

export const TitleBox = styled.div`
  min-height: 100px;
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  & h1 {
    font-family: ${vars.fontTertiary};
  }

  @media (max-width: ${vars.responsiveMin}px) {
    padding: 0 0 0 1em;
    & h1 {
      font-size: 1.5em;
    }
  }
`;

type DescriptionBoxProps = {
  primaryColor: string;
};

export const DescriptionBox = styled.div<DescriptionBoxProps>`
  height: 8vw;
  min-height: 100px;
  padding: 0 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ primaryColor }) => {
    const amount = 0.3;
    try {
      return lighten(amount, `${primaryColor}`);
    } catch {
      return lighten(amount, "#000");
    }
  }};

  & p,
  & span {
    font-size: 1em;
    font-weight: normal;
  }

  & ${ButtonWrapper} {
    margin-left: 1em;
    border: 1px solid #7c7d7c;
  }

  @media (max-width: ${vars.responsiveMin}px) {
    padding: 0 1em;
  }

  @media (max-width: 680px) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 2em 1em;

    & p {
      font-size: 1em;
    }

    & span {
      display: block;
      &:last-child {
        margin: 0.7em 0;
      }
    }

    & > *:first-child {
      margin: 0.7em 0;
    }
  }
`;

export const LogoContainer = styled.div`
  flex: 20%;
  width: 20%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${vars.responsiveMin}px) {
    flex-direction: row-reverse;
    flex: 100%;
    width: 100%;
  }
`;

type EmptyBoxProps = {
  primaryColor: string;
};

export const EmptyBox = styled.div<EmptyBoxProps>`
  width: 100%;
  background-color: ${({ primaryColor }) => {
    const amount = 0.5;
    try {
      return lighten(amount, `${primaryColor}`);
    } catch {
      return lighten(amount, "#000");
    }
  }};
  height: 8vw;
  min-height: 100px;

  @media (max-width: ${vars.responsiveMin}px) {
    flex: 80%;
    width: 80%;
  }
`;

export const LogoBox = styled.div`
  height: 8vw;
  min-height: 100px;
  position: relative;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 60%;
    max-height: 60%;
    width: 80px;
  }

  @media (max-width: ${vars.responsiveMin}px) {
    flex: 20%;
    width: 20%;
  }
`;
