import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { MODE } from "../../config/consts";
import {
  AtRiskSection,
  BannerSection,
  IntroSection,
  SceneAnswerProps,
  TitleProps,
  UserAnswerProps,
} from "./types";
import {
  AnswerText,
  BannerWrapper,
  Container,
  DebriefAtRiskWrapper,
  Intro,
  ReplayButton,
  ReplayScene,
  ReplaySceneWrapper,
  Spacer,
  TitleWrapper,
  UserAnswerWrapper,
  UserContainer,
} from "./styles";
import { useApp, UserAnswerType } from "../../state/app";
import scorm from "../../services/scorm";
import env from "../../env";
import useAppLogic from "../../hooks/use-app-logic";

export function IntroComponent({ text }: IntroSection) {
  const { language } = useAppLogic();
  return (
    <Container>
      <Intro dangerouslySetInnerHTML={{ __html: text?.[language] ?? "" }} />
    </Container>
  );
}

export function BannerComponent({
  image = "",
  title,
  titleColor = "",
}: BannerSection) {
  const { language } = useAppLogic();
  return (
    <BannerWrapper imgSrc={image} variant={!title ? "primary" : "secondary"}>
      {title && (
        <Container>
          <h1 style={{ color: titleColor ?? undefined }}>
            {title?.[language] ?? ""}
          </h1>
        </Container>
      )}
    </BannerWrapper>
  );
}

export function AtRiskComponent({
  image = "",
  quote,
  title,
  titleColor = "",
}: AtRiskSection) {
  const { language } = useAppLogic();
  return (
    <DebriefAtRiskWrapper>
      <Container>
        <h2 style={{ color: titleColor }}>{title?.[language] ?? ""}</h2>
        <div className="row row--1fr-2fr">
          <div className="col">
            <div className="img-card">
              <img src={image} alt={"Debrief"} />
            </div>
          </div>
          <div
            className="col"
            dangerouslySetInnerHTML={{ __html: quote?.[language] ?? "" }}
          />
        </div>
      </Container>
    </DebriefAtRiskWrapper>
  );
}

export function Quote({
  image = "",
  quote,
  title,
  titleColor = "",
}: AtRiskSection) {
  const { language } = useAppLogic();
  return (
    <DebriefAtRiskWrapper>
      <Container>
        <h2 style={{ color: titleColor }}>{title?.[language] ?? ""}</h2>
        <div className="row row--1fr-2fr">
          <div className="col">
            <div className="img-card">
              <img src={image} alt={"Debrief"} />
            </div>
            <div className="group-quote">
              <img
                className="quote"
                src="https://storage.finervision.com/e-learning-cms/uploads/quote.svg"
                alt="quote"
              />
              <img
                className="quote"
                src="https://storage.finervision.com/e-learning-cms/uploads/quote.svg"
                alt="quote"
              />
            </div>
          </div>
          <div
            className="col"
            dangerouslySetInnerHTML={{ __html: quote?.[language] ?? "" }}
          />
        </div>
      </Container>
    </DebriefAtRiskWrapper>
  );
}

export function Title({ text }: TitleProps) {
  const { language } = useAppLogic();
  return (
    <TitleWrapper>
      <Container>
        <h1>{text?.[language] ?? ""}</h1>
      </Container>
    </TitleWrapper>
  );
}

export function UserAnswer({
  text,
  sceneIds,
  image = "https://storage.finervision.com/e-learning-cms/uploads/humility_thumbnail.jpg",
  debriefSceneId,
  currentSectionIndex,
}: UserAnswerProps) {
  const { userAnswer, setDebriefId, setDebriefIndex, client } = useApp();
  const history = useHistory();
  const { language, appText } = useAppLogic();

  const handleRedirectUser = useCallback(
    (sceneId) => {
      if (sceneId) {
        // setDebriefMode(true);
        setDebriefId(debriefSceneId);
        setDebriefIndex(currentSectionIndex);
        history.push(sceneId);
      }
    },
    [
      history,
      setDebriefId,
      debriefSceneId,
      currentSectionIndex,
      setDebriefIndex,
    ],
  );

  function getSceneAnswer(
    userAnswer: UserAnswerType,
    sceneIds: string[],
  ): null | SceneAnswerProps {
    if (!sceneIds) {
      return null;
    }
    for (const sceneId in userAnswer) {
      if (!userAnswer.hasOwnProperty(sceneId)) {
        continue;
      }
      if (sceneIds.includes(sceneId)) {
        return { sceneAnswer: userAnswer[sceneId], sceneId: sceneId };
      }
    }

    return null;
  }

  const getAnswer = getSceneAnswer(userAnswer, sceneIds);
  return (
    <UserAnswerWrapper>
      <Container>
        <UserContainer>
          <AnswerText
            dangerouslySetInnerHTML={{
              __html: `${text?.[language] ?? ""} <p>${
                getAnswer?.sceneAnswer ?? ""
              }</p>`,
            }}
            client={client.name}
          />
          <ReplaySceneWrapper>
            <ReplayScene>
              <Spacer
                backgroundImage={
                  image ??
                  "https://storage.finervision.com/e-learning-cms/uploads/humility_thumbnail.jpg"
                }
              />
              <ReplayButton
                onClick={() => handleRedirectUser(getAnswer?.sceneId)}
                aria-label={
                  appText?.replayScene?.[language] ?? "Replay decision"
                }
              >
                <span>
                  {appText?.replayScene?.[language] ?? "Replay decision"}
                </span>
                <span></span>
              </ReplayButton>
            </ReplayScene>
          </ReplaySceneWrapper>
        </UserContainer>
      </Container>
    </UserAnswerWrapper>
  );
}

export function Replay({
  text,
  image = "https://storage.finervision.com/e-learning-cms/uploads/humility_thumbnail.jpg",
}: UserAnswerProps) {
  const { projectID, client } = useApp();
  const history = useHistory();

  const reset = React.useCallback(() => {
    const { setDebriefMode, setUserAnswer } = useApp.getState();
    setDebriefMode(false);
    setUserAnswer({});
    if (scorm.isConnected()) {
      scorm.setData(null);
      history.push(`/`);
    } else if (MODE.startsWith("web")) {
      localStorage.removeItem(env.localStorage.language);
      localStorage.removeItem(env.localStorage.userAnswer);
      localStorage.removeItem(env.localStorage.languageSelected);
      history.push(`/`);
    } else {
      localStorage.removeItem(env.localStorage.language);
      localStorage.removeItem(env.localStorage.userAnswer);
      localStorage.removeItem(env.localStorage.languageSelected);
      history.push(`/${projectID}`);
    }
  }, [history, projectID]);

  return (
    <UserAnswerWrapper>
      <Container>
        <UserContainer>
          <AnswerText
            dangerouslySetInnerHTML={{
              __html: `${text}`,
            }}
            client={client.name}
          />
          <ReplaySceneWrapper>
            <ReplayScene>
              <Spacer
                backgroundImage={
                  image ??
                  "https://storage.finervision.com/e-learning-cms/uploads/humility_thumbnail.jpg"
                }
              />
              <ReplayButton onClick={reset}>
                <span>Replay experience</span>
                <span></span>
              </ReplayButton>
            </ReplayScene>
          </ReplaySceneWrapper>
        </UserContainer>
      </Container>
    </UserAnswerWrapper>
  );
}
