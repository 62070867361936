import React from "react";
import Reset from "./reset";
import { useApp } from "../../state/app";

export default function GlobalStyle() {
  const { client } = useApp();
  return (
    <>
      <Reset
        primaryColor={client?.primaryColor ?? "#ffb600"}
        secondaryColor={client?.secondaryColor ?? "#e0301e"}
        fontPrimary={client?.fonts?.primary}
        fontSecondary={client?.fonts?.secondary}
        fontTertiary={client?.fonts?.tertiary}
      />
    </>
  );
}
