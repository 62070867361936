import styled from "styled-components";
import vars from "../../styles/vars";

export const VideoWrapper = styled.figure`
  background-color: ${vars.colors.black};
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & video {
    width: auto;
    height: 100vh;
    cursor: pointer;
    outline-color: transparent;

    &:focus {
      outline: none;
    }

    @media (max-aspect-ratio: 16 / 9) {
      width: 100vw;
      height: auto;
    }
  }
`;

export const FieldSet = styled.fieldset<{ showDecisions: boolean }>`
  position: absolute;
  border: none;
  padding: 0;
  margin: 0;
  opacity: ${({ showDecisions }) => (showDecisions ? "1" : "0")};
  visibility: ${({ showDecisions }) => (showDecisions ? "visible" : "hidden")};
  pointer-events: ${({ showDecisions }) => (showDecisions ? "all" : "none")};
  width: 100vw;
  height: 100vh;
`;

type ControlsButtonProps = {
  backgroundImage: string;
  active?: boolean;
  specialActive?: boolean;
  focus?: boolean;
};

export const ControlsButton = styled.button<ControlsButtonProps>`
  height: 60px;
  width: 60px;
  display: block;
  opacity: 0.9;
  background-size: 100% auto;
  background-position: ${({ active, specialActive }) =>
    specialActive ? "center bottom" : active ? "bottom" : "top"};
  color: transparent !important;
  font-size: 0 !important;
  line-height: 0 !important;
  position: relative;
  overflow: visible;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  border: none;
  cursor: pointer;
  background-color: transparent;

  & span {
    opacity: ${({ focus }) => (focus ? 1 : 0)};
    position: absolute;
    font-weight: bold;
    background-color: #fff;
    top: -80px;
    left: 50%;
    transform: translate(-50%);
    color: #000 !important;
    font-size: 18px !important;
    line-height: 1em !important;
    padding: 0.5em 1em;
    transition: opacity 250ms;
    pointer-events: none;
    white-space: nowrap;
    outline-color: red !important;

    &:focus {
      outline-color: red !important;
    }

    @media (max-width: 500px) {
      top: -40px;
      font-size: 14px !important;
    }

    &:before {
      content: "";
      height: 12px;
      width: 12px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
      overflow: hidden;
      line-height: 0.4;
      outline: none;
    }
  }

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline-color: transparent;
    opacity: 1;
  }

  span:focus {
    outline-color: transparent;
  }

  @media (max-width: 500px) {
    height: 45px;
    width: 45px;
  }
`;

type Props = {
  show: boolean;
};

export const VideoControlsWrapper = styled.div<Props>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%) translate(0, 35vh);
  background-color: rgba(0, 0, 0, 0.8);
  height: 100px;
  width: calc(100vw - 40px);
  max-width: 520px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
  pointer-events: ${({ show }) => (show ? "all" : "none")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  transition: ${({ show }) =>
    show
      ? "opacity 300ms, visibility 0s 0ms"
      : "opacity 300ms, visibility 0s 300ms"};

  @media (max-aspect-ratio: 16 / 9) {
    & {
      transform: translate(-50%, -100%) translate(0, 20vw);
    }
  }

  @media (max-width: 600px) {
    height: auto;
  }
`;

export const AnimationContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vw;
  max-height: 120px;
  max-width: 120px;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

type AnimationPlayProps = {
  play: boolean;
};

export const AnimationPlay = styled.div<AnimationPlayProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0.5);
  background-position: center;
  background-size: cover;
  opacity: 0;
  animation: animatePlayPause 1s;
  background-image: ${({ play }) =>
    `url(${play ? "assets/animate-play.svg" : "assets/animate-pause.svg"})`};

  @keyframes animatePlayPause {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    20% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    99% {
      opacity: 0;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

type CountDownProps = {
  show: boolean;
  countTimeTime?: number;
};

export const CountDown = styled.div<CountDownProps>`
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  top: 0;
  height: 5px;
  width: 100vw;
  opacity: 1;
  background-color: var(--color-primary);
  animation: ${({ show, countTimeTime }) =>
    show
      ? `width ${countTimeTime ? `${countTimeTime}s` : "9s"} linear forwards`
      : "none"};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};

  &:focus {
    outline-color: transparent;
  }

  @keyframes width {
    0% {
      width: 100vw;
    }

    100% {
      width: 0vw;
    }
  }
`;

export const Decision = styled.button`
  width: 100%;
  font-size: 1.3em;
  padding: 1.6em 1.5em 2em 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 400ms;
  font-weight: bold;
  color: ${vars.colors.white};
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline-color: transparent;
  }

  @media (max-width: 900px) {
    font-size: 0.9em;
  }

  @media (max-width: 900px) {
    padding: 1em;
  }

  @media (max-height: 400px) {
    font-size: 0.7em;
  }
`;

export const AnswerWrapper = styled.span`
  width: 100%;
`;

export const Question = styled.div<{ showQuestion: boolean }>`
  pointer-events: ${({ showQuestion }) => (showQuestion ? "all" : "none")};
  background-color: rgba(000, 000, 000, 0.5);
  color: #ffffff;
  position: absolute;
  top: 0;
  padding: 1em;
  text-align: center;
  width: 100vw;
  opacity: 1;
  transform: ${({ showQuestion }) =>
    showQuestion ? "transform: translate(0, 0)" : "translate(0, -100%)"};
  transition: ${({ showQuestion }) =>
    showQuestion ? "transform 800ms" : " transform 400ms"};
  outline: none;

  h1 {
    font-size: 2em;
    padding: 1em;
    max-width: 1200px;
    margin: 0 auto;
  }

  &:focus {
    outline-color: black;
  }

  @media (max-width: 700px) {
    font-size: 1.1em;
  }
`;

type DecisionsProps = {
  showDecisions: boolean;
};

export const Decisions = styled.div<DecisionsProps>`
  pointer-events: ${({ showDecisions }) => (showDecisions ? "all" : "none")};
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  transform: ${({ showDecisions }) =>
    showDecisions ? "transform: translate(0, 0)" : "translate(0, 100%)"};
  transition: ${({ showDecisions }) =>
    showDecisions ? "transform 800ms" : " transform 400ms"};
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  &:not(:hover) {
    ${Decision} {
      opacity: 1 !important;
    }
  }

  @media (max-width: 700px) {
    & {
      flex-direction: column;
      padding: 20px 0 30px 0;
    }
  }
`;

export const Skip = styled.button`
  font-family: ${vars.fontPrimary};
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid ${vars.colors.white};
  color: ${vars.colors.white};
  position: absolute;
  font-size: 0.7em;
  font-weight: bold;
  z-index: 999999999999;
  padding: 0.6em 1em 0.5em 1em;
  top: 2vw;
  right: 2vw;
  cursor: pointer;
  min-width: 10em;

  &:hover {
    background-color: rgb(0, 0, 0);
  }
`;
