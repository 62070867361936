import { AnimationContainer, AnimationPlay } from "./styles";
import React from "react";

type AnimatePlayProps = {
  play: boolean;
};

export default function AnimatePlay({ play }: AnimatePlayProps) {
  return (
    <AnimationContainer>
      <AnimationPlay play={play} />
    </AnimationContainer>
  );
}
