import React from "react";
import Select from "react-select";
import {
  colorStyles,
  ContentWrapper,
  Description,
  LineOfBusinessWrapper,
} from "./styles";
import Animate from "../animate/animate";
import Scene from "../scene/scene";
import Button from "../button/button";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";
import { useApp } from "../../state/app";
import useAppLogic from "../../hooks/use-app-logic";
import { LocaleValue } from "../../types";

type OptionProps = { text: LocaleValue; sceneId: string; sceneName: string };
type SelectOption = { value: string; label: string };

type Props = {
  title: LocaleValue;
  options: OptionProps[];
  description?: LocaleValue;
  lastScene?: boolean;
};

export default function LineOfBusiness({
  options,
  title,
  description,
  lastScene = false,
}: Props) {
  const { showSetting } = useApp();
  const wrapper = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [selectOption, setSelectOption] = React.useState<SelectOption | null>(
    null,
  );
  const { appText, language } = useAppLogic();

  React.useMemo(() => {
    document.title = "Line Of Business";
  }, []);

  const formattedOptions = React.useMemo<SelectOption[]>(() => {
    return options.map((option) => {
      return {
        value: option.sceneId,
        label: option.text?.[language] ?? "",
      };
    });
  }, [options, language]);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  React.useEffect(() => {
    wrapper.current?.focus();
  }, []);

  return (
    <Scene>
      <Animate />
      <LineOfBusinessWrapper ref={wrapper}>
        <ContentWrapper>
          <label htmlFor="line-of-business">
            <h1 id="title">
              {title?.[language] ?? "Select your Line of Business/Function"}
            </h1>
          </label>
          {description && (
            <Description
              dangerouslySetInnerHTML={{
                __html: description?.[language] ?? "",
              }}
            />
          )}
          <Select
            options={formattedOptions}
            aria-label={
              title?.[language] ?? "Select your Line of Business/Function"
            }
            placeholder={
              appText?.optionsLabel?.[language] ?? "Choose from list"
            }
            styles={colorStyles}
            onMenuOpen={() => setOpen(true)}
            onMenuClose={() => setOpen(false)}
            inputId="line-of-business"
            onChange={(data) => {
              setSelectOption(data as SelectOption);
            }}
            tabIndex={showSetting ? -1 : 0}
            aria-hidden={showSetting}
          />

          <Button
            style={{
              opacity: open ? "0" : selectOption ? undefined : "0",
              pointerEvents: !selectOption ? "none" : "all",
              visibility: !selectOption ? "hidden" : "visible",
            }}
            backgroundColor={"white"}
            link={selectOption?.value}
          >
            {appText?.continue?.[language] ?? "Continue"}
          </Button>
        </ContentWrapper>
      </LineOfBusinessWrapper>
    </Scene>
  );
}
