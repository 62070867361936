import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  ButtonContainer,
  CloseButton,
  CloseIcon,
  DownloadPDF,
  Icon,
  Knob,
  SettingsBackground,
  SettingsContent,
  SettingsTitle,
  SettingsToggleButton,
  SettingsToggles,
  SettingsWrapper,
  Status,
  StatusText,
  Switch,
  Text,
} from "./styles";
import { useApp } from "../../state/app";
import env from "../../env";
import scorm from "../../services/scorm";
import { MODE } from "../../config/consts";
import useAppLogic from "../../hooks/use-app-logic";
import Tooltip from "@reach/tooltip";
import "@reach/tooltip/styles.css";

type Props = {
  open: boolean;
  setOpen: () => void;
};

export default function Settings({ open, setOpen }: Props) {
  const history = useHistory();
  const sound = useApp((state) => state.sound);
  const captions = useApp((state) => state.captions);
  const videoTranscript = useApp((state) => state.videoTranscript);
  const language = useApp((state) => state.language);
  const projectID = useApp((state) => state.projectID);
  const [show, setShow] = React.useState(false);
  const settingsRef = useRef<HTMLDivElement | null>(null);
  const [showResetConfirmation, setShowResetConfirmation] =
    React.useState(false);
  const { appText } = useAppLogic();

  const toggleSound = React.useCallback(() => {
    const { setSound } = useApp.getState();
    setSound(!sound);
  }, [sound]);

  const toggleCaptions = React.useCallback(() => {
    const { setCaptions } = useApp.getState();
    setCaptions(!captions);
  }, [captions]);

  const resetRequest = React.useCallback(() => {
    setShowResetConfirmation(true);
  }, []);

  const reset = React.useCallback(() => {
    const {
      setShowSetting,
      setDebriefMode,
      setUserAnswer,
      setLanguageSelected,
      setLanguage,
    } = useApp.getState();
    setDebriefMode(false);
    setUserAnswer({});
    setLanguageSelected(false);
    setLanguage("en");
    if (scorm.isConnected()) {
      scorm.setData(null);
      history.push(`/`);
    } else {
      localStorage.removeItem(env.localStorage.language);
      localStorage.removeItem(env.localStorage.userAnswer);
      localStorage.removeItem(env.localStorage.languageSelected);
      history.push(`/${projectID}`);
    }
    setShowResetConfirmation(false);
    setShowSetting(false);
  }, [history, projectID]);

  React.useEffect(() => {
    if (open) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [open]);

  React.useEffect(() => {
    if (show && settingsRef.current) {
      settingsRef?.current?.focus();
    }
  }, [settingsRef, show]);

  const handleKeyPress = React.useCallback(
    (e) => {
      // 27 === Escape
      if (e.keyCode === 27) {
        setOpen();
        setShow(false);
      }
    },
    [setOpen],
  );

  function handleComplete() {
    if (MODE.startsWith("scorm") && scorm.isConnected()) {
      scorm.complete();
    }
    window.open(videoTranscript[language], "_blank");
  }

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  return (
    <SettingsWrapper open={open}>
      <SettingsBackground show={show} />
      <SettingsContent
        show={show}
        aria-hidden={!open}
        ref={settingsRef}
        role="dialog"
        aria-modal="true"
        id="Settings"
        data-title="Settings"
        aria-labelledby="Settings"
      >
        <Tooltip label="Close Settings Modal">
          <CloseIcon
            onClick={() => useApp.getState().setShowSetting(false)}
            aria-label="Close Settings Modal"
            id="Close Settings Modal"
            tabIndex={open ? 0 : -1}
          />
        </Tooltip>

        <SettingsTitle>
          {appText?.settings?.[language] ?? "Settings"}
        </SettingsTitle>
        {!showResetConfirmation && (
          <>
            <SettingsToggles>
              <SettingsToggleButton
                onClick={toggleSound}
                aria-label={`${
                  appText?.toggleSound?.[language] ?? "Toggle Sound"
                } ${
                  sound
                    ? appText?.off?.[language] ?? "Off"
                    : appText?.on?.[language] ?? "On"
                }`}
                id={`${appText?.toggleSound?.[language] ?? "Toggle Sound"} ${
                  sound
                    ? appText?.off?.[language] ?? "Off"
                    : appText?.on?.[language] ?? "On"
                }`}
              >
                <Text>{appText?.sound?.[language] ?? "Sound"}</Text>
                <Status>
                  <StatusText on={sound ? 1 : 0}>
                    {sound
                      ? appText?.on?.[language] ?? "On"
                      : appText?.off?.[language] ?? "Off"}
                  </StatusText>
                </Status>
                <Icon>
                  <Switch on={sound ? 1 : 0}>
                    <Knob on={sound ? 1 : 0} />
                  </Switch>
                </Icon>
              </SettingsToggleButton>
              <SettingsToggleButton
                onClick={toggleCaptions}
                aria-label={`Toggle Captions ${
                  captions
                    ? appText?.off?.[language] ?? "Off"
                    : appText?.on?.[language] ?? "On"
                }`}
                id={`Toggle Captions ${
                  captions
                    ? appText?.off?.[language] ?? "Off"
                    : appText?.on?.[language] ?? "On"
                }`}
              >
                <Text>{appText?.captions?.[language] ?? "Captions"}</Text>
                <Status>
                  <StatusText on={captions ? 1 : 0}>
                    {captions
                      ? appText?.on?.[language] ?? "On"
                      : appText?.off?.[language] ?? "Off"}
                  </StatusText>
                </Status>
                <Icon>
                  <Switch on={captions ? 1 : 0}>
                    <Knob on={captions ? 1 : 0} />
                  </Switch>
                </Icon>
              </SettingsToggleButton>
            </SettingsToggles>
            <ButtonContainer>
              {videoTranscript?.[language] && (
                <DownloadPDF onClick={handleComplete}>
                  {appText?.textOnly?.[language] ?? "Text only version"}
                </DownloadPDF>
              )}
              <CloseButton onClick={resetRequest}>
                {appText?.restart?.[language] ?? "Restart"}
              </CloseButton>
              <CloseButton
                onClick={() => useApp.getState().setShowSetting(false)}
              >
                {appText?.close?.[language] ?? "Close"}
              </CloseButton>
            </ButtonContainer>
          </>
        )}
        {showResetConfirmation && (
          <>
            <p style={{ margin: "1em 0", color: "#ffffff" }}>
              {appText?.restartConfirmation?.[language] ??
                "Do you want to restart the course?"}
            </p>
            <CloseButton onClick={reset}>
              {appText?.yes?.[language] ?? "Yes"}
            </CloseButton>
            <CloseButton onClick={() => setShowResetConfirmation(false)}>
              {appText?.cancel?.[language] ?? "Cancel"}
            </CloseButton>
          </>
        )}
      </SettingsContent>
    </SettingsWrapper>
  );
}
