// @ts-ignore
import { SCORM } from "pipwerks-scorm-api-wrapper";

type Data = null | {
  [prop: string]: any;
};

export enum Status {
  notAttempted = "not attempted",
  browsed = "browsed",
  incomplete = "incomplete",
  failed = "failed",
  completed = "completed",
  passed = "passed",
}

const scorm = {
  startDate: new Date(),

  init() {
    SCORM.handleExitMode = false;
    SCORM.init();
    SCORM.handleExitMode = false;
    SCORM.set("cmi.core.exit", "suspend");
    this.startDate = new Date();
  },

  onConnected(fn: Function) {
    if (this.isConnected()) {
      fn();
      this.startDate = new Date();
    } else {
      requestAnimationFrame(() => this.onConnected(fn));
    }
  },

  isConnected(): boolean {
    return SCORM.connection.isActive;
  },

  getData(): Data {
    try {
      return JSON.parse(SCORM.get("cmi.suspend_data") ?? "{}");
    } catch {
      return null;
    }
  },

  setData(data: Data) {
    const currentData = this.getData();
    SCORM.set(
      "cmi.suspend_data",
      data === null ? "" : JSON.stringify({ ...currentData, ...data }),
    );
  },

  getProgress(): number {
    const score = parseFloat(SCORM.get("cmi.core.score.raw"));
    return isNaN(score) ? 0 : score; // 0 - 100
  },

  setProgress(progress: number) {
    SCORM.set("cmi.core.score.raw", progress);
  },

  setStatus(status: Status) {
    SCORM.set("cmi.core.lesson_status", status);
  },

  getStatus(): Status {
    return SCORM.get("cmi.core.lesson_status") as Status;
  },

  getTime(date: Date): string {
    let ts = (date.getTime() - this.startDate.getTime()) / 1000;
    let sec = ts % 60;

    ts -= sec;
    let tmp = ts % 3600; //# of seconds in the total # of minutes
    ts -= tmp; //# of seconds in the total # of hours

    // convert seconds to conform to CMITimespan type (e.g. SS.00)
    sec = Math.round(sec * 100) / 100;

    let strSec = String(sec);
    let strWholeSec = strSec;
    let strFractionSec = "";

    if (strSec.indexOf(".") != -1) {
      strWholeSec = strSec.substring(0, strSec.indexOf("."));
      strFractionSec = strSec.substring(strSec.indexOf(".") + 1, strSec.length);
    }

    if (strWholeSec.length < 2) {
      strWholeSec = `0${strWholeSec}`;
    }
    strSec = strWholeSec;

    if (strFractionSec.length) {
      strSec = `${strSec}.${strFractionSec}`;
    }

    let hour = "0";
    let min = "0";

    if (ts % 3600 != 0) {
      hour = "";
    } else {
      hour = `${ts / 3600}`;
    }
    if (tmp % 60 != 0) {
      min = "0";
    } else {
      min = `${tmp / 60}`;
    }

    hour = String(hour).padStart(2, "0");
    min = String(min).padStart(2, "0");

    return `${hour}:${min}:${strSec}`;
  },

  complete() {
    console.log("SCORM: complete");
    const time = this.getTime(new Date());
    console.log({ time });
    this.setProgress(100);
    this.setStatus(Status.passed);
    SCORM.set("cmi.core.session_time", time);
    SCORM.save();
    SCORM.quit();
  },
};

// @note for testing
(window as any).scorm = scorm;

export default scorm;
