import styled from "styled-components";
import vars from "../../styles/vars";

export const LocaleWrapper = styled.main`
  height: 100%;
  width: 100%;
  background-image: url("assets/locale-background.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(000, 000, 000);
  overflow-x: hidden;
`;

export const ContentWrapper = styled.section`
  padding: 0.5em;
  margin: auto;

  h1,
  p {
    text-align: center;
    color: ${vars.colors.white};
    margin-bottom: 0.5em;
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 2em;
    }
  }
`;

export const LanguageGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  margin: 2em 0;
`;

export const Language = styled.button`
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 15em;
  max-width: 100%;
  padding: 0.8em;

  span {
    color: ${vars.colors.white};
    font-weight: bold;
  }

  &:focus {
    outline-color: white;
  }

  @media (max-width: 600px) {
    width: 10em;
    padding: 0.5em;

    span {
      font-size: 1em;
    }
  }
  @media (max-width: 500px) {
    width: 8em;

    span {
      font-size: 0.7em;
    }
  }
`;
