import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  padding: 0 2em;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 800px) {
    padding: 0 2em;
  }
  @media (max-width: 500px) {
    padding: 0 1em;
  }
`;
