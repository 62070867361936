import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route } from "react-router-dom";
import GlobalStyle from "./components/global-style/global-style";
import App from "./components/app/app";
import { MODE } from "./config/consts";
import scorm from "./services/scorm";

console.log("v1.0.0");

type Props = {
  children: React.ReactElement;
};

function WaitForScormConnection({ children }: Props) {
  const [connected, setConnected] = React.useState(false);

  React.useEffect(() => {
    scorm.init();

    scorm.onConnected(() => {
      setConnected(true);
    });
  }, []);

  if (!connected) {
    return <div>Connecting...</div>;
  }

  return children;
}

ReactDOM.render(
  <Router>
    <GlobalStyle />
    {MODE === "scorm" && (
      <WaitForScormConnection>
        <Route path="/" component={App} />
      </WaitForScormConnection>
    )}
    {MODE === "web" && <Route path="/" component={App} />}
    {!["scorm", "web"].includes(MODE) && (
      <Route path="/:projectID" component={App} />
    )}
  </Router>,
  document.getElementById("root"),
);
