import React from "react";
import {
  ContentWrapper,
  Language,
  LanguageGrid,
  LocaleWrapper,
} from "./styles";
import Animate from "../animate/animate";
import Scene from "../scene/scene";
import { useApp } from "../../state/app";
import useAppLogic from "../../hooks/use-app-logic";

type Props = {
  locales: { label: string; value: string }[];
};

export default function Locale({ locales }: Props) {
  const { appText, language } = useAppLogic();
  return (
    <Scene showSettings={false}>
      <Animate />
      <LocaleWrapper>
        <ContentWrapper>
          <h1>{appText?.welcome?.[language] ?? "Welcome"}</h1>
          <p>
            {appText?.selectLanguage?.[language] ??
              "Please select your language."}
          </p>
          <LanguageGrid>
            {locales.map((locale, index) => {
              return (
                <Language
                  key={index}
                  onClick={() => {
                    const { setLanguageSelected, setLanguage } =
                      useApp.getState();
                    setLanguage(locale.value);
                    setLanguageSelected(true);
                  }}
                >
                  <span>{locale.label}</span>
                </Language>
              );
            })}
          </LanguageGrid>
        </ContentWrapper>
      </LocaleWrapper>
    </Scene>
  );
}
