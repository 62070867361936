import React from "react";
import {
  DescriptionBox,
  EmptyBox,
  IntroBanner,
  IntroContainer,
  IntroLayoutWrapper,
  ItemContainer,
  ItemsContainer,
  LogoBox,
  LogoContainer,
  TitleBox,
} from "./styles";
import Html from "../html/html";
import Button from "../button/button";
import { useApp } from "../../state/app";
import { Answer, ImageProps, LocaleValue } from "../../types";
import Animate from "../animate/animate";
import Scene from "../scene/scene";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";

type Props = {
  image: ImageProps;
  title: LocaleValue;
  primaryColor: string;
  description: LocaleValue;
  startButtonText: string;
  startButtonBackground: string;
  backgroundColor: string;
  startButtonColor?: string;
  companyLogo: ImageProps;
  answers: Answer[];
  lastScene?: boolean;
};

export default function IntroLayout({
  image,
  title,
  backgroundColor = "#000",
  primaryColor = "#000",
  description,
  startButtonBackground,
  startButtonColor = "#fff",
  companyLogo,
  answers,
  lastScene = false,
}: Props) {
  const { browser, language } = useApp();

  React.useMemo(() => {
    if (!title) return;
    document.title = title?.[language] ?? "";
  }, [title, language]);

  const focusRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    focusRef.current?.focus();
  }, []);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  return (
    <Scene>
      <IntroLayoutWrapper
        style={{
          backgroundColor,
        }}
      >
        <Animate />
        <IntroContainer browser={browser}>
          <IntroBanner backgroundImage={image?.src} />
          <ItemsContainer>
            <ItemContainer>
              <TitleBox
                style={{ backgroundColor: primaryColor }}
                ref={focusRef}
              >
                <Html
                  html={`<h1>${title?.[language]}</h1>`}
                  style={{ color: "#fff" }}
                />
              </TitleBox>
              <DescriptionBox primaryColor={primaryColor}>
                <Html
                  html={description?.[language]}
                  style={{ color: "#fff" }}
                />
                {answers.map((answer, index) => {
                  return (
                    <Button
                      backgroundColor={startButtonBackground}
                      color={startButtonColor}
                      link={answer.sceneId}
                      key={index}
                    >
                      {answer?.text?.[language] ?? "Start"}
                    </Button>
                  );
                })}
              </DescriptionBox>
            </ItemContainer>
            {companyLogo?.src && (
              <LogoContainer>
                <EmptyBox primaryColor={primaryColor} />
                <LogoBox>
                  <img src={companyLogo.src} alt={companyLogo?.alt} />
                </LogoBox>
              </LogoContainer>
            )}
          </ItemsContainer>
        </IntroContainer>
      </IntroLayoutWrapper>
    </Scene>
  );
}
