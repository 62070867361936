import styled from "styled-components";
import vars from "../../styles/vars";

type Props = {
  color?: string;
  backgroundColor?: string;
  variant?: string | null;
};

export const ButtonWrapper = styled.button<Props>`
  padding: 0.5em 2em;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-family: ${vars.fontPrimary};
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  ${({ variant }) => {
    if (variant === "debrief")
      return `
      max-width: 600px;
      display: inline-block;
      margin: 1rem auto;
      border: 1px solid ${vars.colors.gray100}
      background: ${vars.colors.white};
      border: none;
      box-sizing: border-box;
      font-weight: bold;
      font-family: ${vars.fontPrimary};
      line-height: 1;
      padding-bottom: 10px;
    `;
  }};

  @media (max-width: ${vars.responsiveMin}px) {
    margin-left: 0 !important;
    font-size: 0.9em;
  }
`;
