import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useApp } from "../../state/app";

export default function Head() {
  const { client, language } = useApp();

  return (
    <Helmet htmlAttributes={{ lang: language }}>
      <link rel="icon" href={client.logo} />
      {/*<title>{client?.name ?? "App"}</title>*/}
    </Helmet>
  );
}
