import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ButtonWrapper } from "./styles";
import { useApp } from "../../state/app";
import { MODE } from "../../config/consts";

type Props = {
  children: any;
  backgroundColor?: string;
  color?: string;
  link?: string | null;
  onClick?: any | null;
  variant?: string | null;
  style?: any;
};

export default function Button({
  children,
  backgroundColor,
  variant = null,
  color,
  onClick = null,
  link,
  style = {},
}: Props) {
  const history = useHistory();
  const { projectID, language, showSetting } = useApp();

  const handleClick = useCallback(() => {
    link &&
      history.push(
        MODE.startsWith("scorm") || MODE.startsWith("web")
          ? `/scene/${link}`
          : `/${projectID}/scene/${link}`,
      );
    onClick && onClick();
  }, [onClick, history, link, projectID]);

  return (
    <ButtonWrapper
      backgroundColor={backgroundColor}
      variant={variant}
      color={color}
      onClick={handleClick}
      style={style}
      lang={language}
      tabIndex={showSetting ? -1 : 0}
      aria-hidden={showSetting}
    >
      {children}
    </ButtonWrapper>
  );
}
